import { BrowserRouter, Routes, Route } from 'react-router-dom';
import CountDown from './pages/countdown';
import Welcome from './pages/welcome';

export default function Router() {
    return (
        <BrowserRouter>
            <Routes>
                <Route path='/countdown' element={<CountDown />} />
                <Route path='/' element={<Welcome />} />
            </Routes>
        </BrowserRouter>
    )
}