import { useState } from 'react'
import Marquee from 'react-fast-marquee'

import mainLogo from '../assets/picture/logo.svg'
import goNextLogo from '../assets/picture/goNext.svg'

import twitterLogo from '../assets/picture/TwitterLogo.svg'
import discordLogo from '../assets/picture/DiscordLogo.svg'
import githubLogo from '../assets/picture/GithubLogo.svg'
import telegramLogo from '../assets/picture/TelegramLogo.svg'
import linkedinLogo from '../assets/picture/LinkedinLogo.svg'

import walletLogo from '../assets/picture/Wallet.svg'

import slideLogo1 from '../assets/picture/slide-images/1.png';
import slideLogo2 from '../assets/picture/slide-images/2.png';
import slideLogo3 from '../assets/picture/slide-images/3.png';
import slideLogo4 from '../assets/picture/slide-images/4.png';
import slideLogo5 from '../assets/picture/slide-images/5.png';
import slideLogo6 from '../assets/picture/slide-images/6.png';

import arrowUpRightLogo from '../assets/picture/ArrowUpRight.svg';
import closeLogo from '../assets/picture/X.svg';

const Welcome = () => {

    // const [open, setAuth] = useState(false);
    const [open, setOpen] = useState(false);
    const handleClose = () => { setOpen(false); document.body.classList.remove('dialog-open'); };
    const handleOpen = () => { setOpen(true); document.body.classList.add('dialog-open'); };

    return (
        <div className="flex flex-col p-[40px] md:p-[70px] xl:p-[100px]">
            <div className="flex flex-col lg:flex-row lg:items-center justify-between">
                <div className="flex flex-col sm:flex-row sm:items-center gap-x-[20px]">
                    <div className="">
                        <img src={mainLogo} alt="" />
                    </div>
                    <div className="bg-black w-[2px] h-[36px] hidden sm:block"></div>
                    <span className="lexend-500 text-[48px]">AMBASSADOR NFT</span>
                </div>
                <div className="flex flex-row items-end gap-x-[20px]">
                    {
                        open &&
                        <div className="hidden md:block">
                            <div className="flex flex-col items-center gap-y-[10px]">
                                <div className="flex flex-row items-center justify-center rounded-full bg-[#E74E43] w-[200px] h-[50px] gap-x-[10px] cursor-pointer">
                                    <span className="text-white">Mint NFT</span>
                                    <img src={arrowUpRightLogo} alt="" />
                                </div>
                            </div>
                        </div>
                    }
                    <div className="flex flex-col items-center gap-y-[10px]">
                        <span className="">Connect your wallet to join the Mint</span>
                        <div onClick={handleOpen} className="flex flex-row items-center justify-center rounded-full bg-[#E74E43] w-[300px] h-[50px] gap-x-[10px] cursor-pointer">
                            <span className="text-white">Connect Wallet</span>
                            <img src={walletLogo} alt="" />
                        </div>
                    </div>
                </div>
            </div>

            <div className="w-full my-[50px] h-[1px] bg-[#E6E5E5]"></div>

            <Marquee>
                <div className='m-[12px]'><img src={slideLogo1} alt="" className='w-[100px] md:w-[150px]' /></div>
                <div className='m-[12px]'><img src={slideLogo2} alt="" className='w-[100px] md:w-[150px]' /></div>
                <div className='m-[12px]'><img src={slideLogo3} alt="" className='w-[100px] md:w-[150px]' /></div>
                <div className='m-[12px]'><img src={slideLogo4} alt="" className='w-[100px] md:w-[150px]' /></div>
                <div className='m-[12px]'><img src={slideLogo5} alt="" className='w-[100px] md:w-[150px]' /></div>
                <div className='m-[12px]'><img src={slideLogo6} alt="" className='w-[100px] md:w-[150px]' /></div>
                <div className='m-[12px]'><img src={slideLogo1} alt="" className='w-[100px] md:w-[150px]' /></div>
                <div className='m-[12px]'><img src={slideLogo2} alt="" className='w-[100px] md:w-[150px]' /></div>
                <div className='m-[12px]'><img src={slideLogo3} alt="" className='w-[100px] md:w-[150px]' /></div>
                <div className='m-[12px]'><img src={slideLogo4} alt="" className='w-[100px] md:w-[150px]' /></div>
                <div className='m-[12px]'><img src={slideLogo5} alt="" className='w-[100px] md:w-[150px]' /></div>
                <div className='m-[12px]'><img src={slideLogo6} alt="" className='w-[100px] md:w-[150px]' /></div>
            </Marquee>

            <div className="w-full flex flex-col items-center justify-center gap-y-[10px]">
                <div className="pt-[35px] pb-[30px] border-b border-b-[#E6E5E5] w-full flex flex-col md:flex-row justify-between items-start md:items-end">
                    <span className="text-[22px] md:text-[36px]">MINTED</span>
                    <span className="text-[36px] sm:text-[64px] lg:text-[96px] lexend-500">0/2000</span>
                </div>
                <div className="pt-[40px] pb-[40px] border-b border-b-[#E6E5E5] w-full flex flex-col md:flex-row justify-between items-start md:items-end gap-y-[10px]">
                    <span className="text-[22px] md:text-[36px]">MINT REMAINING TIME</span>
                    <div className="flex flex-row gap-x-[10px]">
                        <div className="flex flex-row items-end gap-x-[10px]">
                            <span className="text-[36px] sm:text-[64px] lg:text-[96px] leading-[27px] sm:leading-[48px] lg:leading-[72px] lexend-500">02</span>
                            <span className="text-[14px] leading-[10px]">HH</span>
                        </div>
                        <div className="flex flex-row items-end gap-x-[10px]">
                            <span className="text-[36px] sm:text-[64px] lg:text-[96px] leading-[27px] sm:leading-[48px] lg:leading-[72px] lexend-500">00</span>
                            <span className="text-[14px] leading-[10px]">MM</span>
                        </div>
                        <div className="flex flex-row items-end gap-x-[10px]">
                            <span className="text-[36px] sm:text-[64px] lg:text-[96px] leading-[27px] sm:leading-[48px] lg:leading-[72px] lexend-500">00</span>
                            <span className="text-[14px] leading-[10px]">SS</span>
                        </div>
                    </div>
                </div>
            </div>

            {
                open &&
                <div className="block md:hidden">
                    <div className="flex flex-col items-center gap-y-[10px] mt-[50px]">
                        <div className="flex flex-row items-center justify-center rounded-full bg-[#E74E43] w-[200px] h-[50px] gap-x-[10px] cursor-pointer">
                            <span className="text-white">Mint NFT</span>
                            <img src={arrowUpRightLogo} alt="" />
                        </div>
                    </div>
                </div>
            }

            <div className="flex flex-col lg:flex-row justify-between mt-[100px] lg:mt-[200px] gap-[70px]">
                <div className="flex flex-col gap-y-[30px] lexend-500 max-w-[300px]">
                    <span className="text-[#9A9898] text-[16px]">Subscribe for fresh content & product updates</span>
                    <div className="relative flex flex-row items-center outline-none bg-[#E6E5E5] rounded-full px-[24px] py-[10px]">
                        <input type="text" className="bg-transparent text-[16px] placeholder:text-[#9A9898] outline-none" placeholder='Your email' />
                        <img src={goNextLogo} alt="" className='absolute right-[4px]' />
                    </div>
                </div>

                <div className="grid grid-cols-2 md:grid-cols-3 xl:grid-cols-4 gap-[30px] text-[16px]">
                    <div className="flex flex-col gap-y-[20px]">
                        <span className="text-[14px] text-[#9A9898]">website</span>
                        <span className="cursor-pointer">Home</span>
                        <span className="cursor-pointer">For Developers</span>
                        <span className="cursor-pointer">For Validators</span>
                        <span className="cursor-pointer">F.A.Q.</span>
                    </div>

                    <div className="flex flex-col gap-y-[20px]">
                        <span className="text-[14px] text-[#9A9898]">Developer resources</span>
                        <span className="cursor-pointer">Bug Bounty Program</span>
                        <span className="cursor-pointer">Documentation</span>
                        <span className="cursor-pointer">Cosmos block explorer</span>
                        <span className="cursor-pointer">EVM block explorer</span>
                        <span className="cursor-pointer">Faucet</span>
                    </div>

                    <div className="flex flex-col gap-y-[20px]">
                        <span className="text-[14px] text-[#9A9898]">Apps</span>
                        <span className="cursor-pointer">App Store</span>
                        <span className="cursor-pointer">Google Play</span>
                    </div>

                    <div className="flex flex-col gap-y-[20px]">
                        <span className="text-[14px] text-[#9A9898]">Legal</span>
                        <span className="cursor-pointer">Whitepaper</span>
                        <span className="cursor-pointer">Legal Documents</span>
                    </div>
                </div>

            </div>

            <div className="flex flex-row items-center justify-center gap-x-[5px] text-[#0D0C0C] text-[44px] sm:text-[60px] md:text-[80px] lg:text-[100px] xl:text-[140px] 2xl:text-[180px] lexend-600 mt-[100px]">
                <span className="cursor-pointer logo-text">S</span>
                <span className="cursor-pointer logo-text">W</span>
                <span className="cursor-pointer logo-text">I</span>
                <span className="cursor-pointer logo-text">S</span>
                <span className="cursor-pointer logo-text">S</span>
                <span className="cursor-pointer logo-text">T</span>
                <span className="cursor-pointer logo-text">R</span>
                <span className="cursor-pointer logo-text">O</span>
                <span className="cursor-pointer logo-text">N</span>
                <span className="cursor-pointer logo-text">I</span>
                <span className="cursor-pointer logo-text">C</span>
            </div>

            <div className="flex flex-col lg:flex-row items-start lg:items-center justify-between gap-[20px] mt-[30px]">
                <div className="flex flex-row items-center gap-x-[30px] mt-[30px]">
                    <img src={twitterLogo} alt="" className="cursor-pointer" />
                    <img src={linkedinLogo} alt="" className="cursor-pointer" />
                    <img src={discordLogo} alt="" className="cursor-pointer" />
                    <img src={telegramLogo} alt="" className="cursor-pointer" />
                    <img src={githubLogo} alt="" className="cursor-pointer" />
                </div>

                <span className="text-[#9A9898]">©2023 Sigma Assets GmbH</span>
            </div>

            {/* dialog part */}
            {
                open &&
                <div className="hidden md:block absolute top-0 left-0 w-full h-screen bg-[#403F3F7A] z-10">
                    <div className="w-full h-screen flex flex-row items-center justify-center ">
                        <div className="w-[90%] md:w-[800px] bg-white rounded-2xl flex flex-col gap-y-[50px] p-[50px]">
                            <div className="flex flex-row justify-between items-center">
                                <span className='text-[24px]'>Please enter your name</span>
                                <img onClick={handleClose} src={closeLogo} alt="" className='cursor-pointer' />
                            </div>
                            <div className="flex flex-col gap-y-[10px]">
                                <span className="text-[14px] text-[#9A9898]">Name*</span>
                                <input type="text" className="bg-[#E6E5E5] text-[16px] placeholder:text-[#9A9898] outline-none rounded-[12px] h-[48px] px-[18px]" placeholder='Enter your name' />
                            </div>
                            <div onClick={handleClose} className="flex flex-row items-center justify-center text-white text-[16px] bg-[#0D0C0C] h-[48px] rounded-full cursor-pointer">Submit</div>
                        </div>
                    </div>
                </div>
            }

            {
                open &&
                <div className="block md:hidden absolute top-0 left-0 w-full h-screen bg-[#403F3F7A] z-10">
                    <div className="w-full h-screen flex flex-row items-end justify-center">
                        <div className="w-full bg-white rounded-t-2xl flex flex-col gap-y-[50px] p-[25px]">
                            <div className="flex flex-row justify-between items-center">
                                <span className='text-[21px]'>Please enter your name</span>
                                <img onClick={handleClose} src={closeLogo} alt="" className='cursor-pointer' />
                            </div>
                            <div className="flex flex-col gap-y-[10px]">
                                <span className="text-[14px] text-[#9A9898]">Name*</span>
                                <input type="text" className="bg-[#E6E5E5] text-[16px] placeholder:text-[#9A9898] outline-none rounded-[12px] h-[48px] px-[18px]" placeholder='Enter your name' />
                            </div>
                            <div onClick={handleClose} className="flex flex-row items-center justify-center text-white text-[16px] bg-[#0D0C0C] h-[48px] rounded-full cursor-pointer">Submit</div>
                        </div>
                    </div>
                </div>
            }
        </div>
    )
}

export default Welcome;